import React from 'react';
import styled from '@emotion/styled';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Heading1 from '../../ui/Heading1';
import ParagraphExLarge from '../..//ui/ParagraphExLarge';
import Heading3 from '../../ui/Heading3';
import { Link } from 'gatsby';
import { COLORS } from '../../ui/variables';

const Content = styled.article`
  max-width: 1242px;
  margin: auto;
  padding: 40px 20px;

  section {
    margin-bottom: 60px;
  }

  a {
    color: ${COLORS.BLACK};
    text-decoration: underline;
  }

  a:hover {
    color: ${COLORS.ORANGE};
  }
`;

const Revised = styled.em`
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 60px;
  display: block;
`;

const PrivacyPage = () => {
  return (
    <Layout>
      <SEO title='Privacy Policy' />
      <Content>
        <Heading1>Small Door Inc. Privacy Policy</Heading1>
        <Revised>Last Updated: August 15, 2023</Revised>

        <section>
          <ParagraphExLarge>
            Small Door Veterinary (“Small Door”) offers a full range of pet
            health services, including 24/7 telemedicine services and priority
            access to board-certified specialists.
          </ParagraphExLarge>

          <ParagraphExLarge>
            This Privacy Policy describes how we handle the personal information
            we collect in connection with our website, apps, and related
            services.
          </ParagraphExLarge>
        </section>

        <section>
          <Heading3>Personal Information We Collect</Heading3>
          <ParagraphExLarge>
            We may collect the following categories of information:
          </ParagraphExLarge>
          <ParagraphExLarge>
            <strong>Information you provide</strong>
          </ParagraphExLarge>
          <ParagraphExLarge>
            <ul>
              <li>
                <strong>Account information,</strong> such as your name, phone
                number, email and mailing address.
              </li>
              <li>
                <strong>Payment information</strong> needed to complete your
                purchase of a membership plan or other services (including name,
                payment card information, billing information), and your
                transaction history. Payment information is processed by our
                third-party payment processor Stripe, in accordance with
                Stripe’s privacy policy and terms of service.
              </li>
              <li>
                <strong>Audio and Video Recordings</strong> that are generally
                made and captured within our facilities and veterinary clinics.
              </li>
              <li>
                <strong>Product usage information,</strong> such as the
                information you provide to us when you interact with our
                services, including information about the products and services
                you purchase through our services (such as the product
                information, date of purchase, and information relating to any
                support issue).
              </li>
              <li>
                <strong>Communications</strong> that we exchange with you,
                including when you contact us with questions, feedback, or
                otherwise.
              </li>
              <li>
                <strong>Marketing information,</strong> such as your preferences
                for receiving communications about our services and
                publications, and details about how you engage with our
                communications.
              </li>
            </ul>
          </ParagraphExLarge>
          <ParagraphExLarge>
            <strong>Information we obtain from other sources</strong>
          </ParagraphExLarge>
          <ParagraphExLarge>
            <ul>
              <li>
                <strong>Social media information.</strong> We may maintain pages
                on social media platforms, such as Facebook and Instagram. When
                you visit or interact with our pages on those platforms, you or
                the platforms may provide us with information through the
                platform.
              </li>
              <li>
                <strong>Third-party logins.</strong> When you link, connect, or
                login to the services with a third party service, you direct the
                service to send us information as controlled by that service or
                as authorized by you via your privacy settings at that service.
              </li>
            </ul>
          </ParagraphExLarge>
          <ParagraphExLarge>
            <strong>Automatic data collection</strong>
          </ParagraphExLarge>
          <ParagraphExLarge>
            We and our service providers may automatically log information about
            you, your computer or mobile device, and your interaction over time
            with our services, such as:
          </ParagraphExLarge>
          <ParagraphExLarge>
            <ul>
              <li>
                <strong>Device data,</strong> such as your computer’s or mobile
                device’s operating system, manufacturer and model, browser type,
                IP address, unique identifiers, language settings, mobile device
                carrier, and general location information such as city, state or
                geographic area; and
              </li>
              <li>
                <strong>Usage data,</strong> such as pages or screens you
                viewed, how long you spent on a page, browsing history, and
                access times.
              </li>
            </ul>
          </ParagraphExLarge>
          <ParagraphExLarge>
            We may collect this information using cookies and other similar
            technologies. Cookies are text files that websites store on a
            visitor’s device or in the browser for the purpose of helping you
            navigate between pages efficiently, remembering your preferences,
            enabling functionality, and helping us understand user activity and
            patterns. For more information on how you can control cookies,
            please see the <a href='#privacy-choices'>Privacy Choices</a>{' '}
            section below.
          </ParagraphExLarge>
        </section>

        <section>
          <Heading3>How We Use Personal Information</Heading3>
          <ParagraphExLarge>
            We use personal information for the following purposes:
          </ParagraphExLarge>
          <ParagraphExLarge>
            <strong>To provide our services.</strong> This includes:
          </ParagraphExLarge>
          <ParagraphExLarge>
            <ul>
              <li>Enabling you to create a Small Door account;</li>
              <li>Processing your payments;</li>
              <li>Administering, hosting, and operating our services;</li>
              <li>
                Communicating with you and responding to any inquiries you may
                have; and
              </li>
              <li>
                Analyzing your use of our services to allow us to evaluate and
                improve the services.
              </li>
            </ul>
          </ParagraphExLarge>
          <ParagraphExLarge>
            <strong>
              For research, development, benchmarking, and improving our
              services.
            </strong>{' '}
            We may use personal information to analyze and improve the services,
            identify trends, and operate and expand our business activities. We
            may also create aggregated, anonymized, or other de-identified
            statistics, which we may use for lawful business purposes, including
            for analytics, forecasting, and strategic planning.
          </ParagraphExLarge>
          <ParagraphExLarge>
            <strong>For marketing and advertising,</strong> including for:{' '}
          </ParagraphExLarge>
          <ParagraphExLarge>
            <ul>
              <li>
                <strong>Direct marketing.</strong> We may send you direct
                marketing communications, including, but not limited to, sending
                newsletters or publications, and notifying you of promotions,
                offers and events via postal mail, email, telephone, text
                message, and other means.
              </li>
              <li>
                <strong id='interest-based'>Interest-based advertising.</strong>{' '}
                We may engage third-party advertising companies to display our
                ads on their online services. We may also share information
                about our users with these companies to facilitate advertising
                for our services to them or similar users on other online
                platforms. For more information, or to understand your choices,
                please visit the <a href='#privacy-choices'>Privacy Choices</a>{' '}
                section below.
              </li>
            </ul>
          </ParagraphExLarge>
          <ParagraphExLarge>
            <strong>For compliance and protection,</strong> including to enforce
            any applicable terms and conditions, comply with legal obligations,
            defend against legal claims or disputes, protect the security and
            integrity of our services, and identify and investigate fraudulent,
            harmful, unauthorized, unethical or illegal activity.
          </ParagraphExLarge>
        </section>

        <section>
          <Heading3>How We Share Personal Information</Heading3>
          <ParagraphExLarge>
            We may share personal information with:
          </ParagraphExLarge>
          <ParagraphExLarge>
            <strong>Service providers.</strong> We share personal information
            with companies and individuals that provide services on our behalf
            or help us operate our services or our business (such as hosting
            services, communications, data and cyber security services, billing
            and payment processing services, fraud detection, investigation and
            prevention services, web and mobile analytics, email and
            communication distribution and monitoring services, and customer
            relation management systems).
          </ParagraphExLarge>
          <ParagraphExLarge>
            <strong>Advertising partners.</strong> We may share personal
            information that we collect on our website with third party
            advertising companies (including for the{' '}
            <a href='#interest-based'>interest-based advertising</a> purposes
            described above), lead generation partners, and channel partners,
            resellers, and distributors that allow us to explore and pursue
            growth opportunities.
          </ParagraphExLarge>
          <ParagraphExLarge>
            <strong>Third Parties.</strong> Third parties, such as advertising
            companies, data providers and data co-ops (such as Epsilon), for{' '}
            <a href='#interest-based'>interest-based advertising</a> and other
            marketing purposes, including those third parties’ own purposes.
          </ParagraphExLarge>
          <ParagraphExLarge>
            <strong>Professional advisors.</strong> We share personal
            information with professional advisors, such as lawyers, auditors,
            bankers and insurers, where necessary in the course of the
            professional services that they render to us.
          </ParagraphExLarge>
          <ParagraphExLarge>
            <strong>Authorities and others.</strong> We may share personal
            information with law enforcement, government authorities, and
            private parties, as we believe in good faith to be necessary or
            appropriate.
          </ParagraphExLarge>
          <ParagraphExLarge>
            <strong>Business transferees.</strong> We may share personal
            information with acquirers and other relevant participants in
            business transactions (or negotiations for such transactions)
            involving a corporate divestiture, merger, consolidation,
            acquisition, reorganization, sale or other disposition of all or any
            portion of the business or assets of, or equity interests in, Small
            Door or our affiliates (including, in connection with a bankruptcy
            or similar proceedings).
          </ParagraphExLarge>
        </section>

        <section>
          <Heading3 id='privacy-choices'>Privacy Choices</Heading3>
          <ParagraphExLarge>
            <strong>Access, correct, or delete your information.</strong> To
            keep your information accurate, current, and complete, you may
            modify your profile in your account or{' '}
            <a href='#contact-us'>contact us</a> as specified below. Registered
            users may also contact us to request deletion of your account and
            certain personal information. We will take reasonable steps, subject
            to any limitations under applicable law, to delete information in
            our possession that you have previously submitted via the services.
          </ParagraphExLarge>
          <ParagraphExLarge>
            <strong>Unsubscribe from direct marketing communications.</strong>{' '}
            You may opt out of marketing-related communications by following the
            opt out or unsubscribe instructions contained in the marketing
            communication we send you. You may continue to receive
            service-related and other non-marketing communications.
          </ParagraphExLarge>
          <ParagraphExLarge>
            <strong>Opt out of push notifications.</strong> If you opt in to
            receive push notifications within the app, we may send push
            notifications or alerts to your mobile device from time to time. You
            can deactivate push notifications and alerts at any time by changing
            your device settings, changing the push notification settings within
            the application, or deleting the app.
          </ParagraphExLarge>
          <ParagraphExLarge>
            <strong>Opt out of interest-based advertising.</strong> You may
            limit online tracking by:
          </ParagraphExLarge>
          <ParagraphExLarge>
            <ul>
              <li>
                <strong>Blocking cookies in your browser.</strong> Most browsers
                let you remove or reject third-party cookies, including cookies
                used for interest-based advertising. To do this, follow the
                instructions in your browser settings. Many browsers accept
                cookies by default until you change your settings. For more
                information about cookies, including how to see what cookies
                have been set on your device and how to manage and delete them,
                visit{' '}
                <a href='https://www.allaboutcookies.org' target='_blank'>
                  www.allaboutcookies.org
                </a>
                .
              </li>
              <li>
                <strong>
                  Blocking advertising ID use in your mobile settings.
                </strong>{' '}
                Your mobile device settings may provide functionality to limit
                use of the advertising ID associated with your mobile device for
                interest-based advertising purposes.
              </li>
              <li>
                <strong>Using privacy plug-ins or browsers.</strong> You can
                block our websites from setting cookies used for interest-based
                ads by using a browser with privacy features, like{' '}
                <a href='https://brave.com/' target='_blank'>
                  Brave
                </a>
                , or installing browser plugins like{' '}
                <a href='https://privacybadger.org/' target='_blank'>
                  Privacy Badger
                </a>
                ,{' '}
                <a href='https://www.ghostery.com/' target='_blank'>
                  Ghostery
                </a>
                , or{' '}
                <a href='https://ublock.org/' target='_blank'>
                  uBlock Origin
                </a>
                , and configuring them to block third party cookies/trackers.
                You can also opt out of Google Analytics by downloading and
                installing the browser plug-in available at:{' '}
                <a
                  href='https://tools.google.com/dlpage/gaoptout'
                  target='_blank'
                >
                  https://tools.google.com/dlpage/gaoptout
                </a>
                .
              </li>
              <li>
                <strong>Platform opt outs.</strong> The following advertising
                partners offer opt out features that let you opt out of use of
                your information for interest-based advertising:
              </li>
              <ul>
                <li>
                  Google:{' '}
                  <a
                    href='https://myadcenter.google.com/?sasb=true'
                    target='_blank'
                  >
                    www.adsettings.google.com
                  </a>
                </li>
                <li>
                  Facebook:{' '}
                  <a href='https://www.facebook.com/about/ads' target='_blank'>
                    https://www.facebook.com/about/ads
                  </a>
                </li>
              </ul>
              <li>
                <strong>Advertising industry opt out tools.</strong> You can
                also use these opt out options to limit use of your information
                for interest-based advertising by participating companies:
              </li>
              <ul>
                <li>
                  Digital Advertising Alliance for Websites:{' '}
                  <a
                    href='https://optout.aboutads.info/?c=2&lang=EN'
                    target='_blank'
                  >
                    outout.aboutads.info
                  </a>
                </li>
                <li>
                  Digital Advertising Alliance for Mobile Apps:{' '}
                  <a
                    href='https://youradchoices.com/appchoices'
                    target='_blank'
                  >
                    https://youradchoices.com/appchoices
                  </a>
                </li>
                <li>
                  Network Advertising Initiative:{' '}
                  <a
                    href='https://optout.networkadvertising.org/?c=1'
                    target='_blank'
                  >
                    optout.networkadvertising.org
                  </a>
                </li>
              </ul>
            </ul>
          </ParagraphExLarge>
          <ParagraphExLarge>
            Note that because these opt out mechanisms are specific to the
            device or browser on which they are exercised, you will need to opt
            out on every browser and device that you use.
          </ParagraphExLarge>
          <ParagraphExLarge>
            <strong>Do Not Track.</strong> Some Internet browsers may be
            configured to send “Do Not Track” signals to the online services
            that you visit. We currently do not respond to "Do Not Track" or
            similar signals. To find out more about "Do Not Track," please visit{' '}
            <a href='https://www.allaboutdnt.com' target='_blank'>
              http://www.allaboutdnt.com
            </a>
            .
          </ParagraphExLarge>
        </section>

        <section>
          <Heading3>Data Security</Heading3>
          <ParagraphExLarge>
            We employ a number of technical, organizational and physical
            safeguards designed to protect the personal information we collect.
            However, no security measures are failsafe and we cannot guarantee
            the security of your personal information.
          </ParagraphExLarge>
        </section>

        <section>
          <Heading3>Data Retention</Heading3>
          <ParagraphExLarge>
            We may retain your personal information for as long as it is
            reasonably needed in order to maintain and expand our relationship
            and provide you with our services; in order to comply with our legal
            and contractual obligations; or to protect ourselves from any
            potential disputes. To determine the appropriate retention period
            for personal information, we consider the amount, nature, and
            sensitivity of such information, the potential risk of harm from
            unauthorized use or disclosure of such information, the purposes for
            which we process it, and the applicable legal requirements.
          </ParagraphExLarge>
        </section>

        <section>
          <Heading3>Job Applicants</Heading3>
          <ParagraphExLarge>
            When you visit the <Link to='/careers'>Careers</Link> portion of the
            website, we collect the information that you provide to us in
            connection with your job application. This includes but is not
            limited to business and personal contact information, professional
            credentials and skills, educational and work history and other
            information of the type that may be included in a resume. This may
            also include diversity information that you voluntarily provide. We
            use this information on the basis of our legitimate business
            interests to facilitate our recruitment activities and process
            employment applications, such as by evaluating a job candidate for
            an employment activity, to monitor recruitment statistics and to
            respond to surveys. We may also use this information to provide
            improved administration of the services and as otherwise necessary
            (i) to comply with relevant laws or to respond to subpoenas or
            warrants served on us, (ii) to protect and defend our or others’
            rights or property, (iii) in connection with a legal investigation
            and (iv) to investigate or assist in preventing any violation or
            potential violation of the law, this Privacy Policy or our Terms of
            Use.
          </ParagraphExLarge>
          <ParagraphExLarge>
            SMS terms: Small Door Veterinary, also known as Small Door offers
            you the option to engage in SMS text conversations about your job
            application. By participating, you also understand that message
            frequency may vary depending on the status of your job application,
            and that message and data rates may apply. Please consult your
            carrier for further information on applicable rates and fees.
            Carriers are not liable for delayed or undelivered messages. Reply
            STOP to cancel and HELP for help.
          </ParagraphExLarge>
          <ParagraphExLarge>
            By opting-in to receiving SMS text messages about your job
            application, you acknowledge and agree that your consent data,
            mobile number, and personal information will be collected and stored
            solely for the purpose of providing you with updates and information
            related to your job application. We are committed to protecting your
            privacy and shall not share or sell your consent data, mobile
            numbers, or personal information to third parties under any
            circumstances.
          </ParagraphExLarge>
        </section>

        <section>
          <Heading3>Children</Heading3>
          <ParagraphExLarge>
            Our services are not intended for use by children under 13 years of
            age. If we learn that we have collected personal information through
            our services from a child under 13 without the consent of the
            child’s parent or guardian as required by law, we will delete it.
          </ParagraphExLarge>
        </section>

        <section>
          <Heading3>Changes to This Privacy Policy</Heading3>
          <ParagraphExLarge>
            We reserve the right to modify this Privacy Policy at any time. If
            we make material changes to this Privacy Policy, we will notify you
            by updating the date of this Privacy Policy. We may also provide
            notification of changes in another way that we believe is reasonably
            likely to reach you, such as via e-mail (if you have an account
            where we have your contact information) or another manner.
          </ParagraphExLarge>
        </section>

        <section>
          <Heading3 id='contact-us'>Contact Us</Heading3>
          <ParagraphExLarge>
            You can reach us by email at{' '}
            <a href='mailto:privacy@smalldoorvet.com'>
              privacy@smalldoorvet.com
            </a>{' '}
            or at the following mailing address:
          </ParagraphExLarge>
          <ParagraphExLarge style={{ margin: 0 }}>
            Small Door Veterinary
          </ParagraphExLarge>
          <ParagraphExLarge style={{ margin: 0 }}>
            3 East 28th Street, Floor 5
          </ParagraphExLarge>
          <ParagraphExLarge style={{ margin: 0 }}>
            New York, NY 10016
          </ParagraphExLarge>
        </section>
      </Content>
    </Layout>
  );
};

export default PrivacyPage;
